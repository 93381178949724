import { call, put, takeEvery, fork } from "redux-saga/effects";
import * as actions from "../actions/producto";
import productoService from "../services/producto";

function* fetchProductsByGrupo({ page, id, clienteId }) {
  yield put(actions.startLoading());
  try {
    const productos = yield call(productoService.fetchByGrupo, page, id, clienteId);
    yield put(actions.receiveProductosPorGrupo(productos));
  } catch (err) {
    yield put(actions.receiveProductosPorGrupoError(err));
    console.log(err);
  }
}

function* fetchProducts() {
  yield put(actions.startLoading());
  try {
    const productos = yield call(productoService.fetch);
    yield put(actions.receiveProductos(productos));
  } catch (err) {
    yield put(actions.receiveProductosError(err));
    console.log(err);
  }
}

function* findProducts({ id, clienteId }) {
  yield put(actions.startLoading());
  try {
    const producto = yield call(productoService.find, id, clienteId);
    yield put(actions.receiveProductoSucceeded(producto));
  } catch (err) {
    yield put(actions.receiveProductoError(err));
    console.log(err);
  }
}

function* searchProducts({ id, search }) {
  yield put(actions.startLoading());
  try {
    const productos = yield call(productoService.search, id, search);
    yield put(actions.receiveProductos(productos));
  } catch (err) {
    yield put(actions.receiveProductoError(err));
    console.log(err);
  }
}

function* searchAllProducts({ search, clienteId }) {
  yield put(actions.startLoading());
  try {
    const productos = yield call(productoService.searchAll, search, clienteId);
    yield put(actions.receiveProductosAllSearchSucceeded(productos));
  } catch (err) {
    yield put(actions.receiveProductosAllSearchError(err));
    console.log(err);
  }
}

function* requestDownloadFile(grupoId) {
  yield put(actions.startLoading());
  try {
    const file = yield call(productoService.downloadFile, grupoId);
    yield put(actions.receiveDownloadFile(file));
  } catch (err) {
    yield put(actions.receiveDownloadFileError(err));
  }
}

function* fetchConsultarDisponibilidad({ id, clienteId, cantidad }) {
  yield put(actions.startLoading());
  try {
    const enviado = yield call(
      productoService.consultarDisponibilidad,
      id,
      clienteId,
      cantidad
    );
    yield put(actions.receiveConsultarDisponibilidad(enviado));
  } catch (err) {
    yield put(actions.receiveConsultarDisponibilidadError(err));
    console.log(err);
  }
}

function* watchGetProductsByGrupoRequest() {
  yield takeEvery(
    actions.Types.PRODUCTOS_FETCH_GRUPO_REQUESTED,
    fetchProductsByGrupo
  );
}
function* watchGetProductsRequest() {
  yield takeEvery(actions.Types.PRODUCTOS_FETCH_REQUESTED, fetchProducts);
}
function* watchFindProductsRequest() {
  yield takeEvery(actions.Types.PRODUCTOS_FIND_REQUESTED, findProducts);
}
function* watchSearchProductsRequest() {
  yield takeEvery(actions.Types.PRODUCTOS_SEARCH_REQUESTED, searchProducts);
}
function* watchSearchAllProductsRequest() {
  yield takeEvery(
    actions.Types.PRODUCTOS_SEARCH_ALL_REQUESTED,
    searchAllProducts
  );
}
function* watchGetDownloadFile() {
  yield takeEvery(
    actions.Types.PRODUCTOS_DOWNLOAD_REQUESTED,
    requestDownloadFile
  );
}
function* watchConsultarDisponibilidad() {
  yield takeEvery(
    actions.Types.PRODUCTOS_CONSULTAR_DISPONIBILIDAD_REQUESTED,
    fetchConsultarDisponibilidad
  );
}

const productsSagas = [
  fork(watchGetProductsRequest),
  fork(watchGetProductsByGrupoRequest),
  fork(watchFindProductsRequest),
  fork(watchSearchProductsRequest),
  fork(watchSearchAllProductsRequest),
  fork(watchGetDownloadFile),
  fork(watchConsultarDisponibilidad),
];

export default productsSagas;
