import axios from 'axios';
import  TokenService  from './token';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/Budget/`


export default class PresupuestosService {

    static async downloadFile({numero, comprobante, ruta}) {
        const respuesta = await axios.get(`${ENDPOINT}Download/${numero}/${comprobante}/${ruta}`,{
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        })
        .then(response => {
            return response
        }) 
        return new Blob([respuesta.data], { type: "application/pdf" })
    }

    static async ConfirmarPresupusto(idPresupuesto, observaciones, esPedidoFirme) {
        const respuesta = await axios.post(`${ENDPOINT}${idPresupuesto}?observaciones=${observaciones}&esPedidoFirme=${esPedidoFirme}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetchConfirm(data) {

        const respuesta = await axios.get(`${ENDPOINT}FetchPreconfirm/${data.idPresupuesto}/${data.idUser}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetch(id) {
        const respuesta = await axios.get(`${ENDPOINT}fetchAll/${id}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetchClientes(id) {
        const respuesta = await axios.get(`${ENDPOINT}fetchClientes/${id}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values
    }

    static async find(id) {
        const respuesta = await axios.get(`${ENDPOINT}${id}`,{
            headers: {
                authorization: await TokenService.getAuthHeader()
            }
        })
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async search(search) {
        console.log(search)
        const respuesta = await axios.post(`${ENDPOINT}search`, search )
        .then(response => {
            return response
        })  
        return respuesta.data.data
    }

    static async fetchLatest(id) {
        const respuesta = await axios.get(`${ENDPOINT}latest/${id}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values;
    }

    static async findCarrito(id, clientId) {
        if(id != null && clientId != null){
            const respuesta = await axios.get(`${ENDPOINT}${clientId}/${id}/shoppingCart`)
            .then(response => {
                return response
            })  
            return respuesta.data.data;
        }
    }

    static async getCarrito(id, clientId) {
        if(id != null && clientId != null){
            const respuesta = await axios.get(`${ENDPOINT}${clientId}/${id}/getShoppingCart`)
            .then(response => {
                return response
            })  
            return respuesta.data.data;
        }
    }

    static async findPreconfirmData(presupuestoId) {
        const respuesta = await axios.get(`${ENDPOINT}preconfirm/${presupuestoId}`)
        .then(response => {
            return response
        })
        return respuesta.data.data;
    }

    static async cancelPresupuesto(presupuestoId) {
        const respuesta = await axios.get(`${ENDPOINT}cancel/${presupuestoId}`)
        .then(response => {
            return response
        })
        return respuesta.data.data;
    }

    /* ABM DE PRESUPUESTOS */

    static async addProduct(producto){
        const respuesta = await axios.post(`${ENDPOINT}shoppingCart`,producto)
        .then(response => {
            return response
        })  
        return respuesta.data.data;
    }

    static async deleteProduct(presupuestoId, item){
        const respuesta = await axios.delete(`${ENDPOINT}${presupuestoId}/${item}`)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values;
    }

    static async editProduct(producto, presupuestoId, item){
        const respuesta = await axios.put(`${ENDPOINT}${presupuestoId}/${item}`, producto)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values;
    }

    static async editShoppingCart(presupuesto, presupuestoId){
        const respuesta = await axios.put(`${ENDPOINT}${presupuestoId}`, presupuesto)
        .then(response => {
            return response
        })  
        return respuesta.data.data.$values;
    }

    static async preconfirmPresupuesto(presupuesto){
        const respuesta = await axios.put(`${ENDPOINT}preconfirmBudget`, presupuesto)
        .then(response => {
            return response
        })  
        return respuesta.data.data;
    }
}
